
import { defineComponent } from "vue-demi";
import Promocode from "@/components/promocode/Generation.vue";

export default defineComponent({
  name: "PromocodeGenerationView",
  components: {
    Promocode,
  },
});
